<template>
  <Wrapper :notice="noticeTitle"  :headerLeftStatus="'leftArrow'" class="df-styles">
    <van-form>
    <div class="register-index-password-input margin-10">
      <p v-show="isResetPwd" class="font14 main-text-2 margin-bottom8">{{ $t('auth.resetPassWord') }}</p>
      <p v-show="isNewPwd || isResetLoginPwd" class="font14 main-text-2 margin-bottom8">{{ $t('auth.newPassword') }}</p>
      <van-field
        :type="fieldType"
        v-model="newPwd"
        @input="newPwdFn"
        :placeholder="$t('auth.enterPassword')"
        :error="newPwdErrorTip.length > 0"
      >
        <div slot="right-icon" class="icon-box ub ub-cen" @click="changeFieldType">
          <img v-show="fieldType === 'password'"
            src="@/assets/images/system/close-eye.svg"
            alt="back"
            title="left-arrow"
          />
          <img v-show="fieldType === 'text'"
            src="@/assets/images/system/eye-o.svg"
            alt="back"
            title="left-arrow"
          />
        </div>
      </van-field>
      <!-- :class="{
        'ipt-tip-success-color':newPwd&&!(newPwd.length<8),
        'ipt-tip-error-color':newPwd&&newPwd.length<8,
        }" -->
      <span class="font12 main-text-3 ipt-tip-error-color">{{ newPwdErrorTip }}</span>
    </div>
    <div class="register-index-password-input">
      <p class="font14 main-text-2 margin-bottom8">{{ $t('auth.confirmPassword') }}</p>
      <van-field
        :type="fieldType"
        v-model="confirmPwd"
        @input="confirmPwdFn"
        :placeholder="$t('auth.confirmPassword')"
        :error="checkPwdStatus==='error'"
      >
        <div slot="right-icon" class="icon-box ub ub-cen" @click="changeFieldType">
          <img v-show="fieldType === 'password'"
            src="@/assets/images/system/close-eye.svg"
            alt="back"
            title="left-arrow"
          />
          <img v-show="fieldType === 'text'"
            src="@/assets/images/system/eye-o.svg"
            alt="back"
            title="left-arrow"
          />
        </div>
      </van-field>
      <p v-if="checkPwdStatus==='error'" class="ipt-tip-error-color">{{ $t('auth.confirmPwdErrorTip') }}</p>
    </div>
    </van-form>
    <button class="register-index-wrapper-submit" @click="onNext">
      {{ isResetLoginPwd ? $t('auth.submit') : $t('next') }}
    </button>
  </Wrapper>
</template>
<script>
import { resetPwd, register, resetProfilePwd } from '@/api/auth.js'
import Wrapper from '@/components/auth/wrapper'
import { mapActions } from 'vuex'
export default {
  components: { Wrapper },
  data () {
    return {
      newPwd: '',
      confirmPwd: '',
      showKeyBoard: false,
      fieldType: 'password',
      currentPwd: '',
      checkPwdStatus: 'pending',
      verificationTarget: 'ResetPassWord',
      newPwdErrorTip: ''
    }
  },
  computed: {
    isResetPwd () {
      return this.verificationTarget === 'ResetPassWord'
    },
    isNewPwd () {
      return this.verificationTarget === 'SignUp'
    },
    isResetLoginPwd () {
      return this.verificationTarget === 'ResetLoginPwd' || this.verificationTarget === 'ResetWithdraw'
    },
    noticeTitle () {
      return this.isResetPwd || this.isResetLoginPwd ? this.$t('auth.resetPassWord') : this.$t('auth.setPasswordTitle')
    }
  },
  mounted () {
    this.verificationTarget = this.$store.state.verify.verificationTarget
  },
  methods: {
    resetPwdFn () {
      const email = this.$store.state.auth.email
      const mobile = this.$store.state.auth.phone
      const mobileCode = this.$store.state.auth.mobileCode
      const password = this.newPwd
      const sessionId = this.$store.state.verify.sessionId
      resetPwd({
        email: email,
        mobile: mobile,
        mobile_code: mobileCode,
        password: password,
        session_id: sessionId
      }).then(() => {
        this.$toast.clear()
        this.$router.push('/register/login')
      })
    },
    registerFn () {
      const email = this.$store.state.auth.email
      const mobile = this.$store.state.auth.phone
      const mobileCode = this.$store.state.auth.mobileCode
      const password = this.newPwd
      const sessionId = this.$store.state.verify.sessionId
      register({
        email: email,
        mobile: mobile,
        mobile_code: mobileCode,
        password: password,
        session_id: sessionId
      }).then((res) => {
        const resData = res.data
        this.$store.dispatch('updateToken', resData.token)
        this.$toast.clear()
        this.$router.push('/market')
      })
    },
    onResetProfilePwd () {
      const params = {
        password: this.newPwd
      }
      if (this.verificationTarget !== 'ResetLoginPwd') {
        delete params.password
        params.with_draw_password = this.newPwd
      }
      resetProfilePwd(params).then(res => {
        if (res?.data) {
          this.$toast(this.$t('nav.successText'))
          let path = '/profile'
          if (params.password) {
            path = '/register/login'
            this.LogOut()
          }
          this.$router.push(path)
        }
      })
    },
    changeFieldType () {
      const typeObj = {
        password: 'text',
        text: 'password'
      }
      this.fieldType = typeObj[this.fieldType]
    },
    onBoardInput (value) {
      this.value = value
      this[this.currentPwd] = value
    },
    onNext () {
      this.checkPwd()
      if (this.checkPwdStatus === 'error') {
        return false
      }
      if (!this.checkStorng(this.newPwd)) {
        return false
      }
      if (this.isResetLoginPwd) {
        // 安全修改登录密码与支付密码
        this.onResetProfilePwd()
      } else if (this.$store.state.verify.accountStatus) {
        this.resetPwdFn()
      } else {
        this.registerFn()
      }
    },
    confirmPwdFn () {
      this.confirmPwd = this.confirmPwd.replace(/\s+/g, '')
      this.checkPwd()
    },
    newPwdFn () {
      this.newPwd = this.newPwd.replace(/\s+/g, '')
      if (this.checkStorng(this.newPwd)) {
        return false
      }
    },
    checkStorng (password) {
      const errorTip = {
        minLength: this.$t('auth.newPwdMinLengthTip'),
        maxLength: this.$t('auth.newPwdMaxLengthTip'),
        digit: this.$t('auth.newPwdDigitTip'),
        letter: this.$t('auth.newPwdLetterTip')
      }
      const checkRule = {
        minLength: 8,
        maxLength: 16,
        digit: /\d/,
        letter: /[A-Za-z]/
      }
      if (password.length < checkRule.minLength) {
        this.newPwdErrorTip = errorTip.minLength
        return false
      }
      if (password.length > checkRule.maxLength) {
        this.newPwdErrorTip = errorTip.maxLength
        return false
      }
      if (!checkRule.digit.test(password)) {
        this.newPwdErrorTip = errorTip.digit
        return false
      }
      if (!checkRule.letter.test(password)) {
        this.newPwdErrorTip = errorTip.letter
        return false
      }
      this.newPwdErrorTip = ''
      return true
    },
    checkPwd () {
      this.checkPwdStatus = this.newPwd === this.confirmPwd ? 'success' : 'error'
    },
    ...mapActions(['LogOut'])
  }
}
</script>
<style lang="less">
.register-index-wrapper {
  .margin-10 {
    margin-top: 80px;
  }
  .register-index-password-input {
    margin-top: 32px;
  }

  &-submit {
    margin-top: 48px;
    padding: 24px 0;
    width: 100%;
    height: 96px;
    border-radius: 16px;
    border: none;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 48px; /* 150% */
    background: linear-gradient(
      135deg,
      var(--line-gradient-start-color) 0%,
      var(--line-gradient-end-color) 100%
    );
  }
}
</style>
